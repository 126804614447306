import i18n from "@/plugins/i18n";

let staticData = {
  statisticsPeriods: [],
  invoicesPaymentsStates: [],
  days: [],
  dataTableFooterOptions: {},
  defaultPerPage: 50,
  standardDuration: 30,

  calendar: {
    availability: "availability",
    appointment: "appointment",
  },

  getRating(item) {
    if (item) {
      let out = "";
      for (let n = 1; n <= item.rating; n++) out += "⭐";
      return out;
    }
    return "";
  },

  update: () => {
    staticData.statisticsPeriods = [
      { name: i18n.t("gbl-periods-lastyear"), id: 0 },
      { name: i18n.t("gbl-periods-since1year"), id: 1 },
      { name: i18n.t("gbl-periods-thisyear"), id: 2 },
      { name: i18n.t("gbl-periods-last6months"), id: 3 },
      { name: i18n.t("gbl-periods-last90days"), id: 4 },
      { name: i18n.t("gbl-periods-last60days"), id: 5 },
      { name: i18n.t("gbl-periods-last30days"), id: 6 },
      { name: i18n.t("gbl-periods-lastmonth"), id: 7 },
      { name: i18n.t("gbl-periods-thismonth"), id: 8 },
      { name: i18n.t("gbl-periods-today"), id: 9 },
      { name: i18n.t("gbl-periods-yesterday"), id: 10 },
      { name: i18n.t("gbl-periods-24h"), id: 11 },
      { name: i18n.t("gbl-periods-48h"), id: 12 },
      { name: i18n.t("gbl-periods-date"), id: 13 },
      { name: i18n.t("gbl-periods-all"), id: 14 },
    ];

    staticData.invoicesPaymentsStates = [
      i18n.t("invoice-payment-state-pending"),
      i18n.t("invoice-payment-state-partial"),
      i18n.t("invoice-payment-state-payed"),
      i18n.t("invoice-payment-state-void"),
    ];

    staticData.days = [
      { name: i18n.t("day-monday"), id: 1 },
      { name: i18n.t("day-tuesdays"), id: 2 },
      { name: i18n.t("day-wednesday"), id: 3 },
      { name: i18n.t("day-thursday"), id: 4 },
      { name: i18n.t("day-friday"), id: 5 },
      { name: i18n.t("day-saturday"), id: 6 },
      { name: i18n.t("day-sunday"), id: 7 },
    ];

    staticData.checkinSources = [
      {
        id: null,
        name: i18n.t("gbl-all-female"),
      },
      {
        id: 1,
        name: i18n.t("labcheckin-mng-filter-internal"),
      },
      {
        id: 2,
        name: i18n.t("labcheckin-mng-filter-external"),
      },
    ];

    staticData.invoiceModes = [
      {
        id: null,
        value: i18n.t("gbl-required-selection"),
        disabled: true,
      },
      {
        id: 1,
        value: i18n.t("labcheckin-invoice-type-patient"),
      },
      {
        id: 2,
        value: i18n.t("labcheckin-invoice-type-reference"),
      },
      {
        id: 3,
        value: i18n.t("labcheckin-invoice-type-free"),
      },
    ];

    staticData.locales = [
      {
        id: null,
        value: i18n.t("gbl-required-selection"),
        disabled: true,
      },
      {
        id: "it",
        value: i18n.t("language-it"),
      },
      {
        id: "en",
        value: i18n.t("language-en"),
      },
    ];

    staticData.dataTableFooterOptions = {
      showFirstLastPage: true,
      "items-per-page-options": [
        1, 5, 10, 20, 50, 100, 200, 500, 1000, 5000, 10000,
      ],
    };

    staticData.defaultPerPage = 50;
  },
};

staticData.update();

export default staticData;
